import React, { Component, useState, useEffect } from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Styled } from '../styles/servicosTemplate.styles.js'
import { graphql } from 'gatsby'

import { Title } from '../components/Title'
import { Text } from '../components/Text'
import { CTA } from '../components/CTA'
import { FormAndMap } from '../components/FormAndMap'
import { FormAndTeam } from '../components/FormAndTeam'

const Template = ({ pageContext, data, location }) => {
  const info = pageContext.info // data.markdownRemark holds your post data
  const teamMembers = data.miscJson.teamMembers

  const [isDesktop, setDesktop] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1000)
    }
  }, [])

  var members = []
  teamMembers.filter(
    (member) => member.areas.filter((item) => {
      if (item.btnLink.includes(info.id.split('/')[2]))
        members.push(member)
    }
    )
  )

  if (members.length <= 0)
    if (info.id.includes('harmonizacao-facial')) {
      teamMembers.filter(member => {
        if (member.name.includes("Vívian Andrade"))
          members.push(member)
      })
    }

  var estetica = (info.id === '/tratamentos-dentarios/estetica-dentaria')

  return (
    <Layout isHomePage location={location}>
      <SEO
        title={info.name}
        description={info.name}
        image={info.topImg}
        pathname={info.id}
      />

      <Styled.Container style={{ backgroundImage: `url(${info.bg})` }} >

        <img src={isDesktop ? info.topImg : info.topImgMobile} alt={"bg-" + info.name} className="bg-top background border-bottom-right mb-5" />

        <Title text={info.name} className="blue uppercase text-center family-catamaran pb-5" />

        <section className="content ">
          <div>
            <div id="about" className="about flex-center">
              <div className="about__content">
                <Title text={info.intro.title} />
                <Text text={info.intro.description} className="black" />
                <CTA
                  text={info.intro.btn}
                  url={info.intro.btnLink}
                  className="desktop to-white"
                />
              </div>
              <div className="about__video">
                {estetica ?
                  (<img src={info.intro.video} alt={info.id} />)
                  : <iframe
                    src={info.intro.video}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  />

                }

              </div>

            </div>
          </div>
        </section>


        <section className='separator'>
          <div className='container-hr-blue'>
            <hr />
          </div>
          <div>
            <Title text={info.separatorTitle} className="blue" />
          </div>
          <div className='container-hr-blue'>
            <hr />
          </div>
        </section>

        <section className="content">
          <div>
            <div id="list" className="list flex-center">
              <div className="list__img">
                {info.list.infoExtra.length > 0 ?
                  <>
                    <Title text={'Descrição'} className="gold  subtitle" />
                    {info.list.infoExtra.map((text, index) => (
                      <Text text={text} className="black" key={'text' + index} />
                    ))}
                  </>
                  :
                  <>
                    <img src={info.list.img} alt="img-service"
                      className='max-height-30vw' />
                  </>
                }
              </div>
              <div className="list__content">
                <Title text={'Vantagens'} className="gold  subtitle" />
                {info.list.content.map((item, index) => (
                  <div key={'list' + index} className="d-flex m-3">
                    <img src="https://media.ruivilela.pt/wp-content/uploads/2024/05/Subtracao-137.png" alt="img-service"
                      className='img-icon-list' />
                    <Text text={item} className="black" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="p-top">
          <div className="form" id="marcacao">
            {members.length > 0 ?
              <FormAndTeam corpoclinico={info.team} speciality={info.name} teamMembers={members} />
              : <FormAndMap />
            }
          </div>
        </section>

      </Styled.Container>
    </Layout>
  )
}


export default Template

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        miniTitle
        description
        img
        imgBorder
        video
        slide
      }
    }
    miscJson {
      teamMembers {
        name
        role
        omd
        text
        img
        specialty
        highlight
        hideOnTeam
        areas{
          btn
          btnLink
        }
      }
      serviceTemplateNoDescriptionImg
    }
  }
`
