import React from 'react'
import { Form } from './Form'
import { Title } from './Title'
import { Text } from './Text'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { intlFormat } from 'date-fns'

export const FormAndTeam = ({ corpoclinico, speciality, teamMembers }) => {

  return (
    <StaticQuery
      query={graphql`
        query {
          miscJson {
            alternAddress
            phone1
            phone2
            contactTitle
            form {
              title
              titleSpeciality
              subTitle
            }
          }
        }
      `}
      render={({ miscJson: content }) => (
        <StyledRow >

          <div className="form" >
            <Title text={content.form.titleSpeciality + speciality} className="blue" />
            <Text text={content.form.subTitle} className="blue" />
            <Form className="blue" speciality={speciality} white />
          </div>

          <div className="mini-team">
            {teamMembers.map((member, i) => (
              <div className='container-mini-team'>
                <div className='container-img'>
                  <img src={member.img} alt={member.name} />
                </div>
                <Text text={member.name} className="black" />
                <Text text={member.role + ' ' + member.omd} className="gold" />
              </div>
            ))}
          </div>
        </StyledRow>
      )}
    />
  )
}

const StyledRow = styled.div`
  display: flex;
  background: ${(props) => props.theme.white};
  color: ${({ theme }) => theme.blue} !important;
  padding-top: ${({ theme }) => theme.spacingM};

  > div {
    width: 50%;
  }

  .black{
    color: ${({ theme }) => theme.black} !important;
  }
  .gold{
    color: ${({ theme }) => theme.gold} !important;
  }

  .contact {
    padding-bottom: ${({ theme }) => theme.spacingM};
    text-align: center;

    .title {
      color: ${({ theme }) => theme.blue} !important;
      margin-top: ${({ theme }) => theme.spacingM};
    }

    svg {
      width: 3rem;
      path {
        fill: ${({ theme }) => theme.blue};
      }
    }

    .info > div {
      margin-top: ${({ theme }) => theme.spacingM};
    }

    p {
      font-size: 2rem;
      line-height: 3.5rem;
      color: ${({ theme }) => theme.blue};

      a {
        margin: 0 ${({ theme }) => theme.spacingXXS};
      }
    }
  }

  .mini-team{
    padding: 9rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 7vh;
    
  }

  .container-mini-team{
    height: 20vh;
    max-height: 20vh;
    width: 22vh;
    text-align: center;
    text-transform: uppercase;
    .container-img{
      height: 20vh;
      max-height: 20vh;
      width: 22vh;
      
      overflow: hidden;
      @media (max-width: 1550px){
        max-width: 21rem;
      }
    }
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 0% -40%;
      transform: scale(1.5);
    }
    .gold{
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .text{
      &.black{
        line-height: 1;
        margin-top: 1rem;

      }
    }
  }
  .form {
    padding: ${({ theme }) => theme.spacingL};
    color: ${({ theme }) => theme.blue} !important;
     border-color: ${({ theme }) => theme.blue}!important;

    .text {
      margin-top: ${({ theme }) => theme.spacingS};
      color: ${({ theme }) => theme.blue} !important;
       border-color: ${({ theme }) => theme.blue}!important;
    }
    input, select{
      color: ${({ theme }) => theme.blue} !important;
       border-color: ${({ theme }) => theme.blue}!important;

      ::placeholder, option{
        color: ${({ theme }) => theme.blue} !important;
         border-color: ${({ theme }) => theme.blue}!important;
      }

    }
    .checkbox {
      label, span, input, p{
        color: ${({ theme }) => theme.blue}!important;
         border-color: ${({ theme }) => theme.blue}!important;
      }
      span{
        border-color: ${({ theme }) => theme.blue}!important;
      }
    }
    button{
      color: ${({ theme }) => theme.blue}!important;
    }
  }


  @media screen and (max-width: 1000px) {
    flex-direction: column;


    > div {
      width: 100%;
    }
    .mini-team{
      padding: 3rem;
      padding-top: 0 !important;
      margin-bottom: 3vh;
      grid-template-columns: repeat(2,1fr);
      row-gap: 2vh;
      column-gap: 2vh;
      .container-mini-team{
        height: auto;
        max-height: 100%;
        width: 100%;
        text-align: center;
        text-transform: uppercase;

        img{
          height: 20vh;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .form {
      padding: ${({ theme }) => `${theme.spacingL} ${theme.spacingS}`};
    }
  }
`
