import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  border-top-right-radius: 35rem;

 
  .background {
    object-fit: cover;
    object-position: center;
    object-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    width: 100%;
    
  }
  

  .border-bottom-right{
    border-bottom-right-radius: 35rem;
  }

  .height-auto{
    height: auto !important;
  }

  .pb-5{
    padding-bottom: 5rem;
  }
  .mb-5{
    margin-bottom: 5rem;
  }

  .uppercase{
    text-transform: uppercase;
  }

  .text-center {
    text-align: center;
  } 

  .family-catamaran{
    font-family:  ${({ theme }) => theme.fontFamily};
  }
  .object-fit{
    object-fit: cover;
    object-position: top;

  }

  .full-width{
    width: 100vw !important;
  }

  .slick-slider .slick-next:before, .slick-slider .slick-prev:before{
    color:  ${({ theme }) => theme.blue};
    background-color:  ${({ theme }) => theme.gold};
    font-size: 4vh;
  }

  .slick-slider{
    
    .slick-next, .slick-prev{
      width: 3vh;
      height: 3vh;
      z-index: 99;
    }
    .slick-next {
      right: 5vh;
    }
    .slick-prev {
      left: 5vh;
    }
    svg:hover{
      transition: all 0.3s ;
      path{
        fill:  ${({ theme }) => theme.gold};
        transition: all 0.3s ;
      }
    }
  }

  .container-slide{
    position: relative;
    h3{
      position: absolute;
      top: 40vh;
      left: 7rem;
      font-size: 1.9rem;
      color: white;
      text-transform: uppercase;
    }
    h2{
      position: absolute;
      top: 43vh;
      left: 7rem;
      font-size: 5.5rem;
      color: white;
      font-weight: 400;
    }
    .description{
      position: absolute;
      top: 53vh;
      left: 7rem;
      font-size: 2rem;
      color: white;
      font-weight: 400;
      width: 27%;
      text-align: justify;
    }
    button{
      position: absolute;
      top: 70vh;
      left: 7rem;
    }
    img{
      height: 100vh;
      position: relative;
      z-index: -1;
    }
  }

  .p-top{
    padding-top: ${({ theme }) => theme.spacingL};
  }
  .d-flex{
    display: flex;
  }
  .rounded-section {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    border-top-right-radius: 35rem;
    .about{
      padding-top: 15rem;
    }
  }

  .list{
    padding-top: 0 !important;
  }
    .about, .list{
      padding: ${({ theme }) => theme.spacingL};
      column-gap: 5%;
      .subtitle{
        font-size: 3rem;
        color: ${({ theme }) => theme.gold} !important;
      }

      &__content{
        text-align: left;
        margin: auto;
  
        .title {
          max-width: 65rem;
          color: ${({ theme }) => theme.black};
          font-family: ${({ theme }) => theme.fontFamily};
          font-size: 4rem;
        }
        .subtitle{
          margin-left: 3vh;
          margin-top: 0;
        }
        .text {
          max-width: 65rem;
          margin-top: ${({ theme }) => theme.spacingM};
        }
        .cta {
          margin-top: ${({ theme }) => theme.spacingM};
        }

        svg{
          display: flex;
        }

        .img-icon-list{
          object-fit: contain;
          padding-right: 1vh;
          width: 2.5vh;
        }
      }
      
      .list__img {
        text-align: justify;
        margin: auto;
        width: auto;
        p{
          margin-top: 2vh;    
          max-width: 65rem;

        }
        .title {
          max-width: 65rem;
          color: ${({ theme }) => theme.black};
          font-family: ${({ theme }) => theme.fontFamily};
          font-size: 4rem;
        }
        .max-height-30vw{
          max-height: 30vw;
        }
        
      } 
      .list__content {
        text-align: justify;
        margin: auto;
        width: auto;
        margin-top: 0;
        max-width: 75rem;
        width: 50%;

        .m-3 {
          margin: 3vh;
        }
        .container-svg{
          display: inline-block;
          position: relative;
          width: 5%;
          vertical-align: middle;
          overflow: hidden;

          svg{
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .text {
          max-width: 65rem;
          margin-top: 0;
        }
      }
      &__video {
        width: 50%;
        text-align: center;
  
        iframe, img {
          width: 100%;
          height: calc(50vw / 1.77);
          max-width: 75rem;
          max-height: calc(75rem / 1.77);
        }
      }
    }

    .separator{
      padding: ${({ theme }) => `${theme.spacingL}`};
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      .title{
        font-family: ${({ theme }) => `${theme.fontFamily}`};
        text-align: center;
      }
      .container-hr{
        display: flex;
        align-items: center;
        hr{
          height: 1px;
          width: 100%;
          border: none;
          background-color: ${({ theme }) => `${theme.gold}`};
        }
      }
      .container-hr-blue{
        display: flex;
        align-items: center;
        hr{
          height: 1px;
          width: 100%;
          border: none;
          background-color: ${({ theme }) => `${theme.blue}`};
        }
      }
    }

  .services {
    padding: ${({ theme }) => `${theme.spacingL} ${theme.spacingM}`};
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
        0,
        1fr
      );
    grid-gap: ${({ theme }) => theme.spacingM};
    row-gap: ${({ theme }) => theme.spacingXL};
    place-items: center;
  }

  .team {

    .title {
      color: ${({ theme }) => theme.blue};
      text-align: center;
    }

    .team-grid {
      margin: ${({ theme }) => theme.spacingM} 0;
      padding: 0 ${({ theme }) => theme.spacingM};
    }

    &.harmonizacao {
      .grid-wrapper {
        grid-template-columns: 1fr;

        .team-member {
          justify-content: center;
          margin: 0 auto;
        }
      }
    }
  }


  .blue{
    color: ${({ theme }) => `${theme.blue}`} !important;
  }
  .black{
    color: ${({ theme }) => `${theme.black}`} !important;
  }

  .container-img{
    height: 60vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;
    transition: all 0.3s ;    

    .container-text{
      bottom: 5vh;
      position: absolute;
      text-align: center;
      top: auto;
      width: 100%;
      transition: all 0.3s ;
      .text{
        font-size: 4rem;
        width: 35%;
        margin: auto;
        opacity: 0;
        transition: all 0.3s ;
      }
    }
    :hover{
      transition: all 0.3s ;
      &::before {
        transition: all 0.3s ;
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(255,255,255,0.7);
      }
      .container-text{
        opacity: 1;
        .text{
          opacity: 1;
        }
      }
    }
  }
  @media screen and (max-width: 1920px) {

    

    .services {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

      > div {
        max-width: 40rem;
      }
    }
  }

  @media screen and (max-width: 1275px) {

    .about__content .title{
        font-size: 2rem !important;
    }
    .services {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

      > div {
        max-width: 40rem;
      }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .slick-next{
      right: 0vh !important;
    }
    .slick-prev{
      left: 0vh !important;
    }
    .slick-next, .slick-prev{
      width: 2vh !important;
      height: 2vh !important;
    }
    .services {
      padding: 0;
      grid-gap: 0rem;
      row-gap: 10rem;
      margin-bottom: 5vh;
      display: block;
      display: grid;
      div{
        width: 20vh;
        max-width: 20vh;
      }
    }

    .img-icon-list{
      width: 2vh;
    }

    .team .team-grid {
      padding: 0 ${({ theme }) => theme.spacingXS};
    }

    .container-slide{
      h3{
        top: 70vh;
        left: 3rem;
      }
      h2{
        top:72vh;
        left: 3rem;
      }
      .description{
        top: 79vh;
        width: 75%;
        left: 3rem;
      }
      .object-fit{
        object-position: 65% 50%;
      }
      
    }
    .about{
      padding: 3rem;
      display: block !important;
      .about__video, .about__content {
        width: 100%;
        margin-bottom: 3vh;
      }
      .about__video, .about__video iframe, .about__video img {
        height: 30vh;
      }
      .about__content {
        .title{
          font-size: 3rem;
        }
      }
    }

    .list{
      padding: 3rem;
      display: block;
      .list__content, .list__img{
        width: 100%;
        margin-bottom: 3vh;
        
      }
      .list__img img{
        height: 25vh;
          display: none;
      }
      .list__content{
        margin-right: 0;
        text-align: left;
        .m-3{
          margin: 1vh;
          margin-left: 0;
        }
        .subtitle{
          margin-left: 0vh;
        }
        svg{
          transform: scale(0.5);    
          margin-top: -0.3vh;
        }
      }
    }
    .container-img{
      height: 40vh;
      background-position: 45% 20%;
      &::before{
        background-color: rgba(255,255,255,0.6) !important;
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
      }
      
      .container-text .text{
        opacity: 1;
        width: 70%;
      }
    }
    .separator{
      padding-top: 0;
      display: block;
      
      hr{
        display: none !important;
      }
    }
    
  }
`

export const Styled = {
  Container,
}
